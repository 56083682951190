class Mail {
  constructor(form, recaptchaToken) {
    this.form = form
    this.message = this.buildMessage(form)
    this.token = recaptchaToken
  }

  buildMessage(form) {
    if (!form) return ''

    return `
    <h4>Contact details: </h4>
    <ul style="list-style-type: none;
    padding-left: 12px;">
      <li>Name: ${form.name}</li>
      <li>Company name: ${form.company}</li>
      <li>Business email: ${form.email}</li>
      <li>Phone: ${form.phone}</li>
      <li>Subject: ${form.subject}</li>
    </ul>
    <h4>Message:</h4>
    <p style="max-width: 800px; padding-left: 12px;">${form.message}</p>
    `
  }

  async send() {
    await fetch('/api/send-mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          name: this.form.name,
          company: this.form.company,
          email: this.form.email,
          phone: this.form.phone,
          message: this.message,
          subject: this.form.subject,
          token: this.token,
        },
      }),
    })
  }
}

export default Mail
