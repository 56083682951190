export const ERRORS_LANG = {
  en: {
    NAME_EMPTY: 'Name is required',
    EMAIL_EMPTY: 'E-mail address is required',
    EMAIL_NOT_VALID: 'E-mail address is not valid',
    PHONE_NOT_VALID: 'Phone number is not valid',
    MESSAGE_EMPTY: 'Message is required',
    COMPANY_EMPTY: 'Company name is required',
  },
  es: {
    // update other when we have language switcher working
    NAME_EMPTY: 'El nombre es obligatorio',
    EMAIL_EMPTY: 'La dirección de correo electrónico es obligatoria',
    EMAIL_NOT_VALID: 'La dirección de correo electrónico no es válida',
    PHONE_NOT_VALID: `El número de teléfono no es válido`,
    MESSAGE_EMPTY: 'El mensaje es obligatorio',
    COMPANY_EMPTY: 'El nombre de la empresa es obligatorio',
  },
  fr: {
    NAME_EMPTY: 'Le nom est obligatoire',
    EMAIL_EMPTY: `L'adresse électronique est obligatoire`,
    EMAIL_NOT_VALID: `L'adresse électronique n'est pas valide`,
    PHONE_NOT_VALID: `Le numéro de téléphone n'est pas valide`,
    MESSAGE_EMPTY: `Le message est obligatoire`,
    COMPANY_EMPTY: "Le nom de l'entreprise est obligatoire",
  },
  de: {
    NAME_EMPTY: 'Name ist erforderlich',
    EMAIL_EMPTY: 'E-Mail-Adresse ist erforderlich',
    EMAIL_NOT_VALID: 'Die E-Mail-Adresse ist ungültig',
    PHONE_NOT_VALID: 'Telefonnummer ist ungültig',
    MESSAGE_EMPTY: 'Nachricht ist erforderlich',
    COMPANY_EMPTY: 'Company name is required',
  },
  pl: {
    NAME_EMPTY: 'Name jest wymagane',
    EMAIL_EMPTY: 'Adres email jest wymagany',
    EMAIL_NOT_VALID: 'Adres email jest niepoprawny',
    PHONE_NOT_VALID: 'Telefon jest niepoprawny',
    MESSAGE_EMPTY: 'Wiadomość jest wymagana',
    COMPANY_EMPTY: 'Company name is required',
  },
}

export const STATUS_MESSAGES_LANG = {
  en: {
    SUCCESS: 'Message sent successfully',
    ERROR: 'An error occured while sending a message',
    SENDING: 'Sending...',
    SEND_ANOTHER: 'Send another',
  },
  es: {
    SUCCESS: 'Mensaje enviado exitosamente',
    ERROR: 'Se produjo un error al enviar el mensaje',
    SENDING: 'Enviando...',
    SEND_ANOTHER: 'Enviar otro',
  },
  fr: {
    SUCCESS: 'Message envoyé avec succès',
    ERROR: `Une erreur s'est produite lors de l'envoi d'un message`,
    SENDING: 'Envoi...',
    SEND_ANOTHER: 'Envoyer un autre message',
  },
  de: {
    SUCCESS: '',
    ERROR: '',
    SENDING: '',
    SEND_ANOTHER: '',
  },
  pl: {
    SUCCESS: '',
    ERROR: '',
    SENDING: '',
    SEND_ANOTHER: '',
  },
}

export const FORM_PLACEHOLDERS_LANG = {
  en: {
    NAME: 'Name',
    EMAIL: 'E-mail address',
    PHONE: 'Phone no. (not required)',
    MESSAGE: 'Message',
  },
  es: {
    NAME: 'Nombre',
    EMAIL: 'Dirección de correo electrónico',
    PHONE: 'Número de teléfono (no requerido)',
    MESSAGE: 'Mensaje',
  },
  fr: {
    NAME: 'Nom',
    EMAIL: 'Adresse électronique',
    PHONE: 'Téléphone (non obligatoire)',
    MESSAGE: 'Message',
  },
  de: {
    NAME: '',
    EMAIL: '',
    PHONE: '',
    MESSAGE: '',
  },
  pl: {
    NAME: '',
    EMAIL: '',
    PHONE: '',
    MESSAGE: '',
  },
}
