<template>
  <section v-if="getContactData" class="footer-contact">
    <div :class="withContainer ? 'container' : ''">
      <p v-if="withTitle" class="form-title">
        {{ getContactData.contactTitle[getCurrentLanguage] }}
      </p>
      <div class="row" :class="{ '--large': !withTitle }">
        <div class="col-contact-info" itemscope itemtype="https://schema.org/Person">
          <img
            class="ceo-picture"
            v-lazy-load :data-src="getContactData.ceoBox.image.mediaItemUrl"
            width="1920"
            height="1080"
            alt="Rafał Gaj, CEO picture"
          />
          <address class="contact-info">
            <p class="full-name" itemprop="name">Rafal Gaj</p>
            <p class="position">CEO</p>
            <a
              class="phone"
              title="phone"
              :href="`tel:${getContactData.ceoBox.phone}`"
              itemprop="telephone"
              @click="sendGtag"
              >{{ getContactData.ceoBox.phone }}</a
            >
            <a
              class="email"
              title="email"
              :href="`mailto:${getContactData.ceoBox.email}`"
              itemprop="email"
              >{{ getContactData.ceoBox.email }}</a
            >
          </address>
        </div>
        <transition name="fade" mode="out-in">
          <form
            v-if="!formSended"
            ref="form"
            class="contact-form"
            novalidate
            @submit.prevent="sendForm"
          >
            <label class="label --small">
              <input
                v-model="form.name"
                class="input --name"
                :class="{ '--error': errors.name }"
                :placeholder="getContactData.form.name[getCurrentLanguage]"
                @keyup="validateName"
              />
              <p class="input-error">{{ errors.name }}</p>
            </label>
            <label class="label --small">
              <input
                v-model="form.company"
                class="input --company"
                :class="{ '--error': errors.company }"
                :placeholder="getContactData.form.company[getCurrentLanguage]"
                @keyup="validateCompany"
              />
              <p class="input-error">{{ errors.company }}</p>
            </label>
            <label class="label --small">
              <input
                v-model="form.email"
                class="input --email"
                :class="{ '--error': errors.email }"
                :placeholder="getContactData.form.email[getCurrentLanguage]"
                @keyup="validateEmail"
              />
              <p class="input-error">{{ errors.email }}</p>
            </label>
            <label class="label --small">
              <input
                v-model="form.phone"
                class="input --phone"
                :class="{ '--error': errors.phone }"
                :placeholder="getContactData.form.phone[getCurrentLanguage]"
                @keyup="validatePhone"
              />
              <p class="input-error">{{ errors.phone }}</p>
            </label>
            <label class="label --select">
              <v-select
                v-model="form.subject"
                :options="getSubjects"
                :clearable="false"
                :searchable="false"
                :filterable="false"
              >
                <template #open-indicator="{ attributes }">
                  <ArrowIcon v-bind="attributes" /> </template
              ></v-select>
            </label>
            <label class="label">
              <textarea
                v-model="form.message"
                class="textarea --message"
                :class="{ '--error': errors.message }"
                :placeholder="getContactData.form.message[getCurrentLanguage]"
              ></textarea>
              <p class="input-error">{{ errors.message }}</p>
            </label>

            <div class="send-button-wrapper">
              <AppButton disable-aos
                >{{ getContactData.form.buttonContent[getCurrentLanguage] }} <ArrowLinearIcon
              /></AppButton>
            </div>

            <!-- <div class="recaptcha-container">
              <recaptcha data-badge="inline" />
            </div> -->

            <transition name="fade" mode="out-in">
              <div
                v-if="isSuccessVisible || isErrorVisible || isLoading"
                class="status-messages-box"
                :class="{
                  'success-box': isSuccessVisible,
                  'error-box': !isSuccessVisible && isErrorVisible,
                  'loading-box': !isSuccessVisible && !isErrorVisible,
                }"
              >
                <transition name="fade" mode="out-in">
                  <div v-if="isSuccessVisible" key="success" class="status-message">
                    <p class="content">{{ STATUS_MESSAGES_LANG[getCurrentLanguage].SUCCESS }}</p>
                  </div>
                  <div v-else-if="isErrorVisible" key="failed" class="status-message">
                    <p class="content">{{ STATUS_MESSAGES_LANG[getCurrentLanguage].ERROR }}</p>
                  </div>
                  <div v-else-if="isLoading" key="loading" class="status-message">
                    <p class="content">{{ STATUS_MESSAGES_LANG[getCurrentLanguage].SENDING }}</p>
                  </div>
                </transition>
              </div>
            </transition>
          </form>
          <div v-else class="thank-you">
            <HappyPageIcon></HappyPageIcon>
            <p class="content">{{ STATUS_MESSAGES_LANG[getCurrentLanguage].SUCCESS }}</p>
            <AppButton @click.native="sendAnother">{{
              STATUS_MESSAGES_LANG[getCurrentLanguage].SEND_ANOTHER
            }}</AppButton>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import AppButton from '@/components/common/AppButton'
import ArrowLinearIcon from '@/components/icons/ArrowLinearIcon'
import ArrowIcon from '@/components/icons/ArrowIcon'
import HappyPageIcon from '@/components/icons/HappyPageIcon'
import { ERRORS_LANG, STATUS_MESSAGES_LANG } from '@/utils/lang-consts'
import { isElementInViewport } from '@/utils/is-element-in-viewport'
import Mail from '@/utils/Mail'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'AppFooterContact',
  components: { AppButton, ArrowLinearIcon, HappyPageIcon, ArrowIcon, vSelect },
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
    withContainer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
        subject: '',
      },
      errors: {
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
      },
      isLoading: false,
      isSuccessVisible: false,
      isErrorVisible: false,
      STATUS_MESSAGES_LANG,
      ERRORS_LANG,
      formSended: false,
      formRef: null,
    }
  },
  computed: {
    ...mapGetters(['getContactData', 'getCurrentLanguage']),
    getSubjects() {
      return this.getContactData.form?.subjects?.subject.map(
        (subject) => subject[this.getCurrentLanguage]
      )
    },
  },
  watch: {
    'form.phone'(c) {
      if (c) {
        let phone = c

        if (!/[+0-9]/.test(phone[0])) {
          phone = phone.substring(1)
        }

        phone = phone.charAt(0) + phone.substring(1).replace(/[^0-9]/g, '')

        this.form.phone = phone
      }
    },
    getCurrentLanguage() {
      this.clearValidationErrors()
      this.resetForm()
    },
    $route() {
      this.clearValidationErrors()
    },
    getSubjects(v) {
      if (v && v[0]) this.form.subject = v[0]
    },
  },
  mounted() {
    this.formRef = this.$refs.form

    if (this.formRef) window.addEventListener('scroll', this.handleScroll, { passive: true })
    if (this.getSubjects) this.form.subject = this.getSubjects[0]
  },
  beforeDestroy() {
    this.$recaptcha?.destroy()
  },
  methods: {
    removeFormWhitespaces() {
      for (const key in this.form) {
        if (typeof this.form[key] === 'string') {
          this.form[key] = this.form[key].trim()
          if (key !== 'message') {
            this.form[key] = this.form[key].replace(/\s\s+/g, ' ')
          }
        }
      }
    },
    async loadRecaptcha() {
      window.removeEventListener('scroll', this.handleScroll)
      try {
        await this.$recaptcha.init()
      } catch (e) {
        console.error(e)
      }
    },

    async sendForm() {
      this.removeFormWhitespaces()

      const validate = this.valdate()
      if (validate) {
        const token = await this.$recaptcha.execute('login')

        if (!token) return
        this.showLoading(true)

        const mail = new Mail(this.form, token)

        mail
          .send()
          .then(() => {
            this.showSuccess()
            this.formSended = true
            // eslint-disable-next-line no-undef
            if (process.client) {
              window.gtag('event', 'conversion', { send_to: 'AW-380967499/7tS7COy4uN4CEMu01LUB' })
            }
          })
          .catch(() => {
            this.showError()
          })
          .finally(() => {
            this.showLoading(false)
          })

        // const mail = new Mail(this.form)
      }
    },
    sendAnother() {
      this.formSended = false
      this.clearValidationErrors()
      this.resetForm()
    },

    clearValidationErrors() {
      this.errors.name = ''
      this.errors.phone = ''
      this.errors.email = ''
      this.errors.message = ''
      this.errors.company = ''
    },
    resetForm() {
      this.form.name = ''
      this.form.phone = ''
      this.form.email = ''
      this.form.message = ''
      this.form.company = ''
      if (this.getSubjects) this.form.subject = this.getSubjects[0]
    },
    valdate() {
      this.clearValidationErrors()

      let passedValidation = true

      this.validateName()
      this.validateEmail()
      this.validatePhone()
      this.validateCompany()

      for (const key in this.errors) {
        if (this.errors[key].length) {
          passedValidation = false
          break
        }
      }

      return passedValidation
    },
    validateEmail() {
      this.errors.email = ''
      if (!this.form.email.length)
        this.errors.email = ERRORS_LANG[this.getCurrentLanguage].EMAIL_EMPTY
      const emailRegExp = /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (!emailRegExp.test(this.form.email))
        this.errors.email = ERRORS_LANG[this.getCurrentLanguage].EMAIL_NOT_VALID
    },
    validatePhone() {
      this.errors.phone = ''
      const phoneRegExp = /^$|^(\+)?([0-9]){8,16}$/
      if (!phoneRegExp.test(this.form.phone))
        this.errors.phone = ERRORS_LANG[this.getCurrentLanguage].PHONE_NOT_VALID
    },
    validateMessage() {
      this.errors.message = ''
      if (!this.form.message.length)
        this.errors.message = ERRORS_LANG[this.getCurrentLanguage].MESSAGE_EMPTY
    },
    validateName() {
      this.errors.name = ''
      if (!this.form.name.length) this.errors.name = ERRORS_LANG[this.getCurrentLanguage].NAME_EMPTY
    },
    validateCompany() {
      this.errors.company = ''
      if (!this.form.company.length)
        this.errors.company = ERRORS_LANG[this.getCurrentLanguage].COMPANY_EMPTY
    },
    showLoading(state) {
      this.isLoading = state
    },
    showSuccess() {
      this.isSuccessVisible = true

      setTimeout(() => {
        this.isSuccessVisible = false
      }, 3000)
    },
    showError() {
      this.isErrorVisible = true

      setTimeout(() => {
        this.isErrorVisible = false
      }, 3000)
    },
    handleScroll() {
      if (isElementInViewport(this.formRef)) this.loadRecaptcha()
    },
    sendGtag() {
      if (process.client) {
        window.gtag('event', 'conversion', { send_to: 'AW-380967499/7tS7COy4uN4CEMu01LUB' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/AppFooterContact';
</style>
